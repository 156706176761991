<template>
  <div class="screen-user" ref="model-box" v-loading="isLoading">
    <div class="statistics">
      <span class="statistics-text">平均降量</span>
      <span class="statistics-num">{{ quantityNum || 0 }}%</span>
    </div>
    <div class="title">
      <span>管控前</span>
      <span>管控设备</span>
      <span>管控后</span>
    </div>
    <Chart
      style="height: calc(100% - 0.62rem);"
      ref="chartBox"
      :option="lineOptions"
    ></Chart>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
import { getRiskReductionDetails } from "@/api/BigScreenDisplay";
export default {
  components: { Chart },
  props: {
    year: {
      type: String,
      default: "",
    },
  },
  watch: {
    year: {
      handler(v) {
        this.init();
      },
      deep: true,
    },
  },
  data() {
    return {
      lineOptions: {},
      quantityNum: "",
      typeList: ["登录数"],
      isLoading: false,
    };
  },
  created() {
    // this.$nextTick(() => {
    //   this.init();
    // });
  },
  methods: {
    init() {
      this.isLoading = true;
      getRiskReductionDetails({ year: this.year })
        .then((res) => {
          this.isLoading = false;
          this.quantityNum = res.data.avgRiskReductionRate;
          this.initChats(res.data.riskReductionDetails);
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    getSize(res, type) {
      const clientWidth = this.$refs["model-box"].offsetWidth;
      const clientHeight = this.$refs["model-box"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initChats(dataList = []) {
      const sizeHNum = this.getSize(0.3, 2);
      var myData2 = ["赔付率", "事故数量", "事故金额"];
      var myData = dataList.map(val=> val.label);
      this.lineOptions = {
        baseOption: {
          timeline: {
            show: false,
            top: 0,
            data: [],
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0,0.6)",
            borderColor: "rgba(0,0,0,0.6)",
            textStyle: {
              color: "#fff",
            },
           formatter: function (params, ticket, callback) {
             let str = `${ params[0].axisValue } > ${ params[0].axisIndex === 0 ? '管控前' : '管控后' }</br>`;
             params.forEach((item, idx) => {
               str += '<div style="height: 12px;width:12px;display:inline-block;border-radius:50%;margin-right:6px;background:'+ item.color.colorStops[item.axisIndex ===0 ? 0 : 1].color +'"></div>'+ "<div style='display:inline-block;margin-right:6px;'>"+item.seriesName+"</div>" + item.value + (item.seriesName == '赔付率' ? '%' : item.seriesName == '事故金额' ? '万' : '') +"<br/>";
             });
             return str;
           },
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            },
          },
          legend: {
            bottom: 0,
            left: "center",
            itemWidth: sizeHNum * 1.2,
            itemHeight: sizeHNum * 1.2,
            textStyle: {
              color: "#ffffff",
              fontSize: sizeHNum * 1.6,
            },
            data: myData2,
          },
          grid: [
            {
              left: sizeHNum * 5,
              top: sizeHNum * 1,
              bottom: sizeHNum * 7,
              width: "31%",
            },
            {
              show: false,
              left: "53.5%",
              top: sizeHNum * 1,
              bottom: sizeHNum * 7,
            },
            // right
            {
              show: false,
              right: sizeHNum * 5,
              top: sizeHNum * 1,
              bottom: sizeHNum * 7,
              width: "31%",
            },
          ],
          xAxis: [
            {
              type: "value",
              inverse: true,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              position: "bottom",
              axisLabel: {
                show: false,
                textStyle: {
                  color: "white",
                  fontSize: sizeHNum * 1.6,
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  // 使用深浅的间隔色
                  color: ["#B5B5B5"],
                  type: "dashed",
                  opacity: 0.5,
                },
              },
            },
            {
              gridIndex: 1,
              show: false,
            },
            {
              gridIndex: 2,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              position: "bottom",
              axisLabel: {
                show: false,
                interval: 0,
                textStyle: {
                  color: "white",
                  fontSize: sizeHNum * 1.6,
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  // 使用深浅的间隔色
                  color: ["#B5B5B5"],
                  type: "dashed",
                  opacity: 0.5,
                },
              },
            },
          ],
          yAxis: [
            {
              type: "category",
              inverse: true,
              position: "right",
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              data: myData,
            },
            {
              gridIndex: 1,
              type: "category",
              inverse: true,
              position: "left",
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#ffffff",
                  fontSize: sizeHNum * 1.8,
                },
              },
              data: myData.map(function (value) {
                return {
                  value: value,
                  textStyle: {
                    align: "center",
                  },
                };
              }),
            },
            {
              gridIndex: 2,
              type: "category",
              inverse: true,
              position: "left",
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              data: myData,
            },
          ],
          series: [],
        },
        options: [
          {
            series: [
              {
                name: "赔付率",
                type: "bar",
                xAxisIndex: 0,
                yAxisIndex: 0,
                barWidth: sizeHNum * 0.8,
                barGap: 2,
                label: {
                  show: true,
                  position: "left",
                  formatter: (params) => {
                    let str = (params.value || 0) + "%";
                    return str;
                  },
                  fontSize: sizeHNum * 1.6,
                  color: "#FFFD37",
                  offset: [0, 0],
                },
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "#0086FF",
                      },
                      {
                        offset: 1,
                        color: "rgba(0,134,255,0)",
                      },
                    ]),
                    barBorderRadius: [sizeHNum * 0.8, 0, 0, sizeHNum * 0.8],
                  },
                },
                data: dataList.map(val=> val.beforeControlRate),
              },
              {
                name: "事故数量",
                type: "bar",
                xAxisIndex: 0,
                yAxisIndex: 0,
                barGap: 2,
                barWidth: sizeHNum * 0.8,
                label: {
                  show: true,
                  position: "left",
                  formatter: (params) => {
                    let str = (params.value || 0);
                    return str;
                  },
                  fontSize: sizeHNum * 1.6,
                  color: "#FFFD37",
                  offset: [0, 0],
                },
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "#96F5F8",
                      },
                      {
                        offset: 1,
                        color: "rgba(150,245,248,0)",
                      },
                    ]),
                    barBorderRadius: [sizeHNum * 0.8, 0, 0, sizeHNum * 0.8],
                  },
                },
                data: dataList.map(val=> val.beforeControlCaseNum),
              },
              {
                name: "事故金额",
                type: "bar",
                xAxisIndex: 0,
                yAxisIndex: 0,
                barWidth: sizeHNum * 0.8,
                label: {
                  show: true,
                  position: "left",
                  formatter: (params) => {
                    let str = (params.value || 0) + "{a|万}";
                    return str;
                  },
                  fontSize: sizeHNum * 1.6,
                  color: "#FFFD37",
                  offset: [0, 0],
                  rich: {
                    a: {
                      color: "#fff",
                      fontSize: sizeHNum * 1.4,
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "#A051FF",
                      },
                      {
                        offset: 1,
                        color: "rgba(160,81,255,0)",
                      },
                    ]),
                    barBorderRadius: [sizeHNum * 0.8, 0, 0, sizeHNum * 0.8],
                  },
                },
                data: dataList.map(val=> val.beforeControlCompensateAmount),
              },
              {
                name: "赔付率",
                stack: "yj",
                type: "bar",
                xAxisIndex: 2,
                yAxisIndex: 2,
                barGap: 2,
                barWidth: sizeHNum * 0.8,
                label: {
                  show: true,
                  position: "right",
                  formatter: (params) => {
                    let str = (params.value || 0) + "%";
                    return str;
                  },
                  fontSize: sizeHNum * 1.6,
                  color: "#FFFD37",
                  offset: [0, 0],
                  rich: {
                    a: {
                      color: "#fff",
                      fontSize: sizeHNum * 1.4,
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "rgba(0,134,255,0)",
                      },
                      {
                        offset: 1,
                        color: "#0086FF",
                      },
                    ]),
                    barBorderRadius: [0, sizeHNum * 0.8, sizeHNum * 0.8, 0],
                  },
                },
                data: dataList.map(val=> val.afterControlRate),
              },
              {
                name: "事故数量",
                type: "bar",
                xAxisIndex: 2,
                yAxisIndex: 2,
                barGap: 2,
                barWidth: sizeHNum * 0.8,
                label: {
                  show: true,
                  position: "right",
                  formatter: (params) => {
                    let str = (params.value || 0);
                    return str;
                  },
                  fontSize: sizeHNum * 1.6,
                  color: "#FFFD37",
                  offset: [0, 0],
                },
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "rgba(150,245,248,0)",
                      },
                      {
                        offset: 1,
                        color: "#96F5F8",
                      },
                    ]),
                    barBorderRadius: [0, sizeHNum * 0.8, sizeHNum * 0.8, 0],
                  },
                },
                data: dataList.map(val=> val.afterControlCaseNum),
              },
              {
                name: "事故金额",
                type: "bar",
                xAxisIndex: 2,
                yAxisIndex: 2,
                barWidth: sizeHNum * 0.8,
                label: {
                  show: true,
                  position: "right",
                  formatter: (params) => {
                    let str = (params.value || 0) + "{a|万}";
                    return str;
                  },
                  fontSize: sizeHNum * 1.6,
                  color: "#FFFD37",
                  offset: [0, 0],
                  rich: {
                    a: {
                      color: "#fff",
                      fontSize: sizeHNum * 1.4,
                    },
                  },
                },
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "rgba(160,81,255,0)",
                      },
                      {
                        offset: 1,
                        color: "#A051FF",
                      },
                    ]),
                    barBorderRadius: [0, sizeHNum * 0.8, sizeHNum * 0.8, 0],
                  },
                },
                data: dataList.map(val=> val.afterControlCompensateAmount),
              },
            ],
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.screen-user {
  position: relative;
  height: 100%;
  padding: 1%;
  .statistics {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: max-content;
    height: 0.26rem;
    margin-left: 3%;
    padding: 4px;
    background-image: url("../../../../assets/images/bigScreen/service/risk-statistics.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &-text {
      font-size: 0.12rem;
      color: #fff;
      margin-right: 6px;
    }
    &-num {
      font-family: "ZhanKuQingKeHuangYouTi";
      font-size: 0.16rem;
      color: rgba(255, 253, 55, 1);
    }
  }
  .title {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 0.36rem;
    font-size: 0.14rem;
    color: #fff;
  }
}
</style>