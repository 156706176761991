<template>
  <div class="model-box" ref="model-box" v-loading="isLoading">
    <!-- 赔付案件量分布趋势 -->
    <div class="tendency">
      <header-name class="tendency-title" title="赔付案件量分布趋势" />
      <Chart
        style="height: 100%; min-height: 100px"
        ref="chartBox"
        :option="lineOptions"
      ></Chart>
    </div>
    <!-- 0赔付案件情况 -->
    <div class="situation">
      <header-name class="tendency-title" title="0赔付案件情况" />
      <div
        class="situation-box"
        v-for="(item, index) in situationoptions"
        :key="index"
      >
        <div class="situation-box-left">
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.label"
            placement="top"
          > <div class="situation-box-left">{{ item.label }}</div>
          </el-tooltip></div>
        <div class="situation-box-progress">
          <el-tooltip
            class="item"
            effect="dark"
            :content="'预计赔付：' + item.estimateAmount + '万'"
            placement="top"
          >
            <div
              class="situation-box-progress-scale"
              :style="{ width: (item.caseRate || 0) + '%' }"
            ></div>
          </el-tooltip>
        </div>
        <div class="situation-box-right">
          <span>{{ item.value }}</span
          >件
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderName from "@/views/BigScreenDisplay/components/HeaderName";
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
import { getCompensateCaseDetails } from "@/api/BigScreenDisplay";
export default {
  props: {
    year: {
      type: String,
      default: "",
    },
  },
  components: { HeaderName, Chart },
  data() {
    return {
      lineOptions: {},
      situationoptions: [],
      isLoading: false,
    };
  },
  watch: {
    year: {
      handler(v) {
        this.init();
      },
      deep: true,
    },
  },
  created() {
    // this.$nextTick(() => {
    //   this.init();
    // });
  },
  methods: {
    init() {
      this.isLoading = true;
      getCompensateCaseDetails({ year: this.year }).then((res) => {
      this.isLoading = false;
        this.situationoptions = res.data.zeroCompensateCaseDetails || [];
        this.initChart(res.data.monthCompensateAmount);
      }).catch(err=>{
      this.isLoading = false;
      });
    },
    initChart(datalist) {
      const sizeHNum = this.getSize(0.3, 2);
      let cities = [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ];
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            color: "#fff",
          },
        },
        grid: {
          borderWidth: 0,
          top: "21%",
          bottom: "12%",
          left: "20%",
          right: "20%",
          color: "#fff",
        },
        legend: {
          top: "1%",
          right: "3%",
          data: ["数量", "金额"],
          icon: "pin",
          textStyle: {
            color: "#ffffff",
            fontSize: sizeHNum * 0.8,
          },
        },

        calculable: true,
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "rgba(13, 151, 235, .3)",
              },
            },
            axisTick: {
              //刻度线
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            axisLabel: {
              color: "#ffffff",
              formatter: function (value) {
                let valueArr = [];
                if (value.length > 4) {
                  let str1 = value.slice(0, 5);
                  let str2 = value.slice(5);
                  valueArr = [str1, str2];
                } else {
                  valueArr = [value];
                }

                return valueArr.join("");
              },
            },
            data: cities,
          },
        ],
        yAxis: [
          {
            type: "value",
            position: "left",
            axisLine: {
              lineStyle: {
                color: "#575E71",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "rgba(13, 151, 235, .3)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              color: "#fff",
            },
            splitArea: {
              show: false,
            },
            // max: max_left,
            // interval: interval_left,
            splitNumber: 5,
          },
          {
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#575E71",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "rgba(13, 151, 235, .3)",
              },
            },
            position: "right",
            axisLabel: {
              formatter: "{value}",
              color: "#fff",
            },
            splitNumber: 5,
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            barMaxWidth: sizeHNum * 2,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 1, color: "#1BDFFC" },
                { offset: 0, color: "#1251D2" },
              ]),
              borderRadius: [6, 6, 0, 0],
              label: {
                show: false,
                position: "inside",
                formatter: function (p) {
                  return p.value > 0 ? p.value : "";
                },
              },
            },
            data: datalist.map((val) => val.estimateAmount),
          },
          {
            name: "数量",
            yAxisIndex: 1,
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#FFFD37",
              borderRadius: 0,
              label: {
                show: false,
                position: "top",
                formatter: function (p) {
                  return p.value > 0 ? p.value + "%" : "";
                },
              },
            },
            data: datalist.map((val) => val.value),
          },
        ],
      };
    },
    getSize(res, type) {
      const clientWidth = this.$refs["model-box"].offsetWidth;
      const clientHeight = this.$refs["model-box"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  height: 100%;
  .tendency {
    position: relative;
    height: 45%;
    &-title {
      position: absolute;
      top: 3%;
      left: 3%;
    }
  }
  .situation {
    position: relative;
    height: 55%;
    overflow: auto;
    padding: 8% 3% 3%;
    &-box {
      display: flex;
      align-items: center;
      color: #fff;
      margin-top: 1.6%;
      &-left {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 1.6rem;
        font-size: 0.14rem;
      }
      &-progress {
        flex: 1;
        height: 0.18rem;
        background-color: rgba(2, 102, 202, 0.1);
        margin: 0 4px;
        border-radius: 0.09rem;
        overflow: hidden;
        &-scale {
          height: 0.18rem;
          background: linear-gradient(90deg, #ff9d1d 0%, #fffd37 100%);
          border-radius: 0.09rem;
        }
      }
      &-right {
        width: 0.88rem;
        font-size: 0.1rem;
        span {
          font-family: "ZhanKuQingKeHuangYouTi";
          margin-right: 2px;
          font-size: 0.16rem;
        }
      }
    }
  }
}
</style>