<template>
  <div class="model-box" ref="model-box" v-loading="isLoading">
    <div class="model-box-charts">
      <Chart style="height: 100%" ref="pieBox" :option="pieOptions" />
    </div>
    <div class="model-box-charts">
      <Chart style="height: 100%" ref="lineBox" :option="barOptions" />
    </div>
  </div>
</template>

<script>
import { getCaseProjectDetails } from "@/api/BigScreenDisplay";
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
export default {
  props: {
    year: {
      type: String,
      default: "2023",
    },
    projectTypeId: {
      type: [String,Number],
      default: "",
    },
  },
  watch: {
    year: {
      handler(v) {
        this.init();
      },
      deep: true,
    },
    projectTypeId: {
      handler(v) {
        v !== '' ? this.init() : '';
      },
      deep: true,
    },
  },
  data() {
    return {
      pieOptions: {},
      barOptions: {},
      isLoading: false
    };
  },
  components: { Chart },
  created() {
    // this.$nextTick(() => {
    //   this.init();
    // });
  },
  methods: {
    init() {
      this.isLoading = true;
      getCaseProjectDetails({
        type: this.projectTypeId || '2',
        year: this.year
      }).then(res=>{
        this.isLoading = false;
        this.setPieOptions(res.data.pieGraphData);
        this.setBarOptions(res.data.barGraphData);
      }).catch(err=>{
        this.isLoading = false;
      })
    },
    getSize(res, type) {
      const clientWidth = this.$refs["model-box"].offsetWidth;
      const clientHeight = this.$refs["model-box"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    setPieOptions(dataList) {
      const sizeHNum = this.getSize(0.3, 2);
      this.pieOptions = {
        tooltip: {
          show: true,
          confine: true,
          backgroundColor: "rgba(9,103,138,0.8)",
          textStyle: {
            color: "rgba(255,255,255,1)",
          },
          trigger: "item",
          position: "top",
          borderColor: "transparent",
          extraCssText: "0px 0px 8px 0px rgba(0,0,0,0.2);border-radius: 4px;",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: "1%",
          top: "center",
          itemWidth: sizeHNum * 1.4,
          itemGap: sizeHNum * 1,
          textStyle: {
            color: "#ffffff",
            fontSize: sizeHNum * 1.2,
          },
        },
        icon: "circle",
        color: [
          "RGBA(4, 186, 207, 1)",
          "RGBA(92, 216, 166, 1)",
          "RGBA(91, 144, 250, 1)",
          "RGBA(110, 200, 235, 1)",
          "RGBA(93, 112, 145, 1)",
          "RGBA(146, 111, 201, 1)",
          "RGBA(232, 105, 73, 1)",
          "RGBA(255, 157, 76, 1)",
          "RGBA(246, 189, 22, 1)",
          "RGBA(255, 152, 197, 1)",
        ],
        grid: {},
        series: [
          {
            type: "pie",
            center: ["36%", "50%"],
            label: {
              show: true,
              color: "#96A2B5",
              position: "outside",
              formatter: function (e) {
                return (
                  "{name|" + e.name + "}\n{value|" + e.data.value + "%}"
                );
              },
              rich: {
                name: {
                  fontSize: sizeHNum * 1.2,
                  color: "#ffffff",
                  padding: [0, 0, 0, 0],
                },
                value: {
                  fontSize: sizeHNum * 1.4,
                  color: "#FFFD37",
                  padding: [2, 0, 0, 0],
                },
                percent: {
                  fontSize: sizeHNum * 1.6,
                  color: "rgba(255, 255, 255, 0.6)",
                },
              },
            },
            showEmptyCircle: true,
            labelLine: {
              lineStyle: {
                color: "#ffffff",
                type: "dashed",
              },
            },
            emptyCircleStyle: {
              color: "#ffffff",
            },
            data: dataList.map(val=>{
              return {
                name: val.label,
                value: val.ratio,
              }
            })
          },
        ],
      };
    },
    setBarOptions(dataList) {
      const sizeHNum = this.getSize(0.3, 2);
      let legendData = [];
      let xAxisData = [];
      let series = [];
      for (const key in dataList) {
        legendData.push(key);
        series.push({
          name: key,
          type: "bar",
          barWidth: sizeHNum * 1,
          itemStyle: {
            normal: {
              barBorderRadius: [sizeHNum * 0.8, sizeHNum * 0.8, 0, 0],
            },
          },
          data: dataList[key].map(val=> val.ratio),
        });
        if (!xAxisData.length) {
          xAxisData = dataList[key].map(val=> val.label);
        }
      }
      // dataList.map(val=> {
      //   return {
      //     // name: val.label,
      //     name: '2021',
      //     type: "bar",
      //     barWidth: sizeHNum * 1,
      //     itemStyle: {
      //       normal: {
      //         barBorderRadius: [sizeHNum * 0.8, sizeHNum * 0.8, 0, 0],
      //       },
      //     },
      //     data: [val.ratio],
      //   }
      // });
      this.barOptions = {
        tooltip: {
          trigger: "axis",
           formatter: function (params, ticket, callback) {
             let str = `${ params[0].axisValue }</br>`;
             params.forEach((item, idx) => {
               str += '<div style="height: 12px;width:12px;display:inline-block;border-radius:50%;margin-right:6px;background:'+ item.color +'"></div>'+ "<div style='display:inline-block;margin-right:6px;'>"+item.seriesName+"</div>" + item.value + "%<br/>";
             });
             return str;
           }
        },
        color: ['rgba(255, 114, 0, 1)', 'rgba(255, 157, 29, 1)', 'rgba(255, 253, 55, 1)'],
        grid: {
          left: "4%",
          right: "25%",
          bottom: "3%",
          top: "8%",
          containLabel: true,
        },
        legend: {
          data: legendData,
          right: "1%",
          top: "center",
          textStyle: {
            color: "#ffffff",
            fontSize: sizeHNum * 1.2,
          },
          orient: "vertical",
          itemWidth: sizeHNum * 1.4,
          itemGap: sizeHNum * 1,
        },
        icon: "circle",
        xAxis: {
          type: "category",
          data: xAxisData,
          axisLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)",
            },
          },
          axisTick: {
            //刻度线
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
            fontSize: sizeHNum * 1.2,
            },
          },
        },

        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#575E71",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)",
            },
          },
          axisLabel: {
            formatter: function (value) {
              return value + '%';
            },
            textStyle: {
              color: "#fff",
            fontSize: sizeHNum * 1.2,
            },
          },
        },
        series: series,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3%;
  &-statistic {
    height: 15%;
    flex: none !important;
    display: flex;
    &-ornament {
      width: 0.18rem;
      height: 100%;
    }
    &-center {
      flex: 1;
      height: 100%;
      background-image: url("../../../../assets/images/bigScreen/policy/statistic-center.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      padding: 0.8%;
      &-num {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        text-align: center;
        font-family: "ZhanKuQingKeHuangYouTi";
        font-size: 0.17rem;
        color: rgba(255, 253, 55, 1);
      }
      &-text {
        font-size: 0.13rem;
        font-weight: 300;
        color: #ffffff;
        text-align: center;
      }
    }
  }
  > div {
    flex: 1;
  }
}
</style>