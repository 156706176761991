<template>
  <div class="model-box" ref="model-box" v-loading="isLoading">
    <div class="item" ref="model-box-item" :style="{ width: carouselHeight + 'px' }">
      <img
        src="../../../../assets/images/bigScreen/service/flow1.png"
        alt=""
        srcset=""
      />
      <div class="item-box">
        <div class="item-box-title">
          当前接案 <span class="item-box-title-num">{{ flowData.receiveCaseNum }}</span
          ><span class="item-box-title-text">件</span>
        </div>
        <div class="item-box-content">
          <div class="item-box-content-a">途径发布：</div>
          <div class="item-box-content-statistical">
            <div
              class="item-box-content-statistical-box"
              v-for="(item, index) in statisticalData"
              :key="index"
              :class="{ 'item-box-content-statistical-margin': index > 0 }"
            >
              <img
                src="../../../../assets/images/bigScreen/service/flow1-r.png"
                alt=""
                srcset=""
              />
              <span>{{ item.name }}{{ flowData[item.key] || 0 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img ref="aa-img"
      src="../../../../assets/images/bigScreen/service/flow-direction.png"
      alt=""
      srcset=""
    />
    <div class="item" :style="{ width: carouselHeight + 'px' }">
      <img
        src="../../../../assets/images/bigScreen/service/flow2.png"
        alt=""
        srcset=""
      />
      <div class="item-box">
        <div class="item-box-title">
          验标定损中 <span class="item-box-title-num">{{ flowData.checkLossCaseNum }}</span
          ><span class="item-box-title-text">件</span>
        </div>
        <div
          class="item-box-content"
          style="padding: 0; height: 72%"
          ref="carousel-box"
        >
          <el-carousel
            trigger="click"
            indicator-position="none"
            style="height: 100%"
          >
            <el-carousel-item
              v-for="item in flowData.checkLossCarouselImages"
              :key="item"
            >
              <img
                :src="item"
                style="width: 100%; height: 100%"
                alt=""
                srcset=""
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <img
      src="../../../../assets/images/bigScreen/service/flow-direction.png"
      alt=""
      srcset=""
    />
    <div class="item" :style="{ width: carouselHeight + 'px' }">
      <img :style="{ width: carouselHeight + 'px' }"
        src="../../../../assets/images/bigScreen/service/flow3.png"
        alt=""
        srcset=""
      />
      <div class="item-box">
        <div class="item-box-title">
          理算结案中 <span class="item-box-title-num">{{ flowData.calculateCaseNum }}</span
          ><span class="item-box-title-text">件</span>
        </div>
        <div class="item-box-content" style="padding: 0; height: 72%">
          <el-carousel
            trigger="click"
            style="height: 100%"
            indicator-position="none"
          >
            <el-carousel-item
              v-for="item in flowData.calculateCarouselImages"
              :key="item"
            >
              <img
                :src="item"
                style="width: 100%; height: 100%"
                alt=""
                srcset=""
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCaseProcessDetails } from "@/api/BigScreenDisplay";
export default {
  data() {
    return {
      statisticalData: [
        {
          name: "线下：",
          key: "offlineReceiveCaseNum",
        },
        {
          name: "线上：",
          key: "onlineReceiveCaseNum",
        },
        {
          name: "硬件报警：",
          key: "AlarmReceiveCaseNum",
        },
      ],
      flowData: {
        calculateCarouselImages: [],
        checkLossCarouselImages: [],
      },
      carouselHeight: 0,
      isLoading: false,
    };
  },
  created() {
  },
  methods: {
    init() {
      this.$nextTick(() => {
        const width = (this.$refs["model-box"].offsetWidth - (this.$refs["aa-img"].offsetWidth * 2)) / 3;
        const height = this.$refs["model-box"].offsetHeight;
        this.carouselHeight = width * 0.9352 > height * 0.98 ? parseFloat(height * 0.98).toFixed(2) : width;
      });
      this.isLoading = true;
      getCaseProcessDetails().then((res) => {
        this.isLoading = false;
        this.flowData = res.data;
        this.$emit('changeNum', res.data.avgClaimDuration)
      }).catch(err=>{
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > img {
    width: 0.5rem;
    transform: translateY(100%);
  }
  > div {
    // flex: 1;
    position: relative;
    > img {
      width: 100%;
    }
    .item-box {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 6%;
      padding-left: 7%;
      padding-right: 5.6%;
      &-title {
        display: flex;
        align-items: center;
        height: 21%;
        padding-left: 28%;
        font-size: 0.14rem;
        color: #ffffff;
        &-num {
          font-family: "ZhanKuQingKeHuangYouTi";
          font-size: 0.18rem;
          color: rgba(255, 253, 55, 1);
          margin-left: 4%;
        }
        &-text {
          font-size: 0.12rem;
          color: #ffffff;
        }
      }
      &-content {
        position: relative;
        margin-top: 8%;
        padding: 4%;
        &-a {
          font-size: 0.14rem;
          color: #ffffff;
        }
        &-statistical {
          margin-top: 9%;
          font-size: 0.14rem;
          color: #ffffff;
          &-box {
            display: flex;
            align-items: center;
            margin-left: 22%;
          }
          &-margin {
            margin-top: 2%;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.model-box {
  .item-box {
    .item-box-content {
      .el-carousel__container {
        height: 100%;
      }
    }
  }
}
</style>