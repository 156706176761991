<template>
  <div class="ModalMap" ref="ModalMap" v-loading="isMapLoading">
    <Chart
      :option="mapOption"
      style="width: 100%; height: calc(100% - 15%)"
    ></Chart>
    <div class="statistics-box">
      <span>服务区域数：{{ statisticsData.caseAreaTotal || 0 }}</span>
      <div style="width: 20px"></div>
      <span>服务案件数：{{ statisticsData.caseTotal || 0 }}</span>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Chart from "@/components/Chart/Charts";
import china from "@/components/Chart/china";
import CenterMap from "@/components/CenterMap";
import ModelBg2 from "../ModelBg2";
import PiChart from "../../PiChart.vue";

import { getMapData } from "@/api/BigScreenDisplay";
// import { getProductTypeIconName } from '@/utils/getProductTypeIconName';
import vehiclePicture from "@/utils/vehiclePicture";
import ElIcon from "../../../../components/Icon/ElIcon.vue";

import { getMapCarNumberInfo } from "@/api/Map/mapRequst.js";

echarts.registerMap("china", china);
export default {
  components: {
    Chart,
    CenterMap,
    ElIcon,
    ModelBg2,
    PiChart,
  },
  props: {
    mapName: {
      type: String,
      default: "china",
    },
  },
  data() {
    return {
      mapOption: {}, // 地图配置
      data: [],
      chartMap: "chartMap",
      productList: [], // 产品种类list
      carStatisticsList: [
        {
          label: "作业(台)",
          img: require("../../../../assets/images/bigScreen/carNum3.png"),
          value: 0,
          option: {},
        },
        {
          label: "通电(台)",
          img: require("../../../../assets/images/bigScreen/carNum2.png"),
          value: 0,
          option: {},
        },
        {
          label: "怠速(台)",
          img: require("../../../../assets/images/bigScreen/carNum4.png"),
          value: 0,
          option: {},
        },
        {
          label: "熄火(台)",
          img: require("../../../../assets/images/bigScreen/carNum5.png"),
          value: 0,
          option: {},
        },
        {
          label: "离线(台)",
          img: require("../../../../assets/images/bigScreen/carNum1.png"),
          value: 0,
          option: {},
        },
      ],
      isMapLoading: false,
      statisticsData: {}
    };
  },
  watch: {
  },
  created() {
    this.imgUrl = new Image();
    this.imgUrl.src =
      "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/geo.png";
    this.imgUrl.style =
      "position: absolute;left: 10px;right: 0;top: 0;bottom: 0;";
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.init();
    // });
  },
  methods: {
    init() {
      this.isMapLoading = true;
      getMapData().then(res=>{
        this.isMapLoading = false;
        this.statisticsData = res.data;
        this.mapOption = this.getMapOption(res.data.mapDataList.map(val=> {return {...val,name: val.label}}));
      }).catch(err=>{
        this.isMapLoading = false;
      })
    },
    provinceShort(name) {
      if (name.startsWith("内蒙")) return "内蒙古";
      if (name.startsWith("黑龙")) return "黑龙江";
      return name.substring(0, 2);
    },
    getMapOption(data) {
      const that = this;
      const mapFeatures = echarts.getMap(this.mapName).geoJson.features;
      const mapData = mapFeatures?.map((item) => {
        return {
          name: item.properties ? item.properties.name : "",
          value: item.properties ? item.properties.childNum : "",
          code: item.properties ? item.properties.adcode : '',
          cp: item.properties
            ? item.properties.cp || item.properties.centroid
            : "",
        };
      });
      const getSize = (res) => {
        console.log(this.$refs);
        const clientWidth = this.$refs["ModalMap"].offsetWidth;
        if (!clientWidth) return;
        const fontSize = 100 * (clientWidth / 1920);
        return res * fontSize;
      };
      // 地图标点图片
      let symbolImg = [
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num1.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num2.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num3.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num4.png",
        "image://https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/num5.png",
      ];
      const convertData = (areaValue) => {
        const res = [];
        for (let i = 0; i < areaValue.length; i++) {
          mapData?.forEach((v) => {
            // if (v.name.indexOf(this.provinceShort(areaValue[i].name)) > -1) {
            if (v.code == areaValue[i].code) {
              const value = v.cp.concat(areaValue[i].value);
              res.push({
                name: areaValue[i].name,
                value: value,
                label: {
                  show: true,
                  position: "top",
                  verticalAlign: "top",
                  align: "center",
                  offset: [0, -sizeW * 47 * 0.5 * 0.05],
                  // padding: 4,
                  // backgroundColor: '#091B59',
                  // borderWidth: 3,
                  // borderColor: '#23E1DB',
                  // borderRadius: 800,
                  distance: -sizeW * 2,
                  formatter(param) {
                    return `{sty1|${param.data.value[2]}}`;
                  },
                  rich: {
                    sty1: {
                      fontSize: 14,
                      //textAlign: 'center',
                      color: "rgba(98, 255, 168, 1)",
                      fontWeight: 600,
                      fontSize: sizeW * 1,
                    },
                  },
                  textStyle: {
                    color: "#ffffff",
                  },
                },
              });
            }
          });
        }
        return res;
      };
      const regions = data.map((item) => {
        return {
          name: this.provinceShort(item.name),
          // itemStyle: {
          //   normal: {
          //     areaColor: "#D94E5D",
          //     color: "transparent",
          //   },
          // }
        };
      });
      let sizeW = getSize(0.3);
      // let sizeW = 12;
      return {
        // tooltip: {
        //   trigger: 'item',
        //   formatter: '{b}<br/>{c}'
        // },
        visualMap: {
          show: true,
          type: "piecewise",
          hoverLink: false,
          itemGap: 0,
          itemSymbol: 'reca',
          inverse: true,
          itemWidth: 40,
          align: 'left',
          pieces: [
            {
              max: 100,
              color: "#A994F6",
            },
            {
              min: 100,
              max: 299,
              color: "#A479FB",
            },
            {
              min: 300,
              max: 499,
              color: "#6F35E7",
            },
            {
              min: 500,
              max: 1000,
              color: "#470BC2",
            },
            {
              min: 1000,
              color: "#350596",
            }
          ],
          color: ["#fff"],
         calculable: true,
         textStyle: {
          color: '#fff'
         },
         bottom: 0,
         right:20
        },
        geo: [
          {
            map: this.mapName,
            show: false,
            roam: false, //不开启缩放和平移
            zoom: 1.25, //视角缩放比例
            bottom: 10,
            roam: false, //是否允许缩放
            layoutSize: "95%",
            layoutCenter: ["50%", "50%"],
            label: {
              normal: {
                show: true, //地区名称
                fontSize: sizeW * 0.9,
                color: "#ffffff",
              },
              emphasis: {
                textStyle: {
                  color: "rgba(243, 248, 254, 1)",
                },
              },
            },
            emphasis: {
              label: {
                show: 0,
                color: "#fff",
              },
            },
            zlevel: 3,
            regions: regions,
          },
        ],
        series: [
          {
            type: "map",
            map: "china",
            zoom: 1.152,
            // top: '30',
            zlevel: 1,
            left: "center",
            align: "right",
            label: {
              normal: {
                show: true,
                fontSize: sizeW * 0.9,
                color: "#ffffff",
              },
              emphasis: {
                show: true,
                fontSize: sizeW * 1.4,
                color: "#FFFD37",
              },
            },
            itemStyle: {
              normal: {
                borderColor: "#112b3b", //省市边界线
                borderColor: "#a7e4e6", //省市边界线
                shadowColor: "rgba(166, 230, 236, 0)",
                areaColor: {
                        image: this.imgUrl,
                        repeat: "repeat",
                      },
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 120,
              },
              emphasis: {
                areaColor: "none",
                borderColor: "red",
              },
            },
            select: {
              disabled: true
            },
            data: data.map(val=>{
              let ind = val.name.indexOf('省') > -1 || val.name.indexOf('市') > -1 || val.name.indexOf('自治区') > -1 || val.name.indexOf('维吾尔') > -1 || val.name.indexOf('壮族自治区') > -1;
               return {
                ...val,
                name: ind ? val.name.substr(0,(val.name.indexOf('省') > -1 ? val.name.indexOf('省') : val.name.indexOf('维吾尔') > -1 ? val.name.indexOf('维吾尔') : val.name.indexOf('壮族自治区') > -1 ? val.name.indexOf('壮族自治区') : val.name.indexOf('市') > -1 ? val.name.indexOf('市') : val.name.indexOf('自治区'))) : val.name
               }
            }),
          },
        ],
      };
    },


  },
};
</script>
<style lang="scss" scoped>
.chartpi {
  height: 20px;
  width: auto;
}
.topTitle {
  // z-index: 9;
  // position: absolute;
  height: 14.3%;
  width: 100%;
  .carTotal-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    > div {
      width: 58px;
      display: flex;
      flex-direction: column;
      color: #ffffff;
      font-size: 0.14rem;
      text-align: center;
      height: 100%;
      > div {
        position: relative;
        flex: 1;
        > img {
          position: absolute;
          height: 100%;
          right: 50%;
          transform: translateX(50%);
          top: 0;
        }
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 0.11rem;
        }
      }
    }
  }
}
.back {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 32px;
  height: 32px;
  background: rgba(0, 138, 255, 0.16);
  border: 1px solid #008aff;
  background-image: url("../../../../assets/screen/back.png");
  background-repeat: no-repeat;
  background-position: center;
}
.ModalMap {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  .identification-box {
    position: absolute;
    top: 25px;
    left: 20px;
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      img {
        width: 0.46rem;
        height: 0.48rem;
      }
      span {
        font-size: 0.16rem;
        font-weight: bold;
        color: #ffffff;
        margin-left: 0.08rem;
      }
    }
  }
  .statistics-box {
    position: absolute;
    bottom: 25px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // width: 2.68rem;
    height: 0.5rem;
    font-size: 0.16rem;
    color: #96f5f8;
    padding: 0 0.4rem;
    background-image: url("../../../../assets/images/bigScreen/home/mapzs.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
.info-content {
  width: 20%;
}
.el-empty {
  margin: 20px 0;
}
.top-tow {
  width: 100%;
  height: 88%;
  overflow: auto;
  color: #ffffff;
  line-height: 30px;
  .item {
    display: flex;
    flex-flow: row;
    img {
      height: 20px;
      margin: 8px 5px;
    }
    label {
      width: 52%;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 5px;
    }
  }
}
::v-deep .el-loading-text {
  color: #19bafd;
  font-size: 16px;
  font-weight: bold;
}
::v-deep .el-icon-loading {
  color: #19bafd;
  font-size: 26px;
  font-weight: bold;
}
</style>