<template>
  <div id="BigScreenDisplay" ref="BigScreenDisplay" class="data-center">
    <!-- 布局头部 -->
    <div class="header" :class="{'bg1':header1,'bg4':header4}">
      <!-- :class="{'bg1':header1,'bg1':header2,'bg1':header3}" -->
      <div class="path-left">
        <img
          src="../../assets/images/bigScreen/equipment/goHome.png"
          @click="handleGoPath('BigScreenDisplay')"
          class="go-home"
          alt=""
          srcset=""
        />
      </div>
      <div class="header_text_weather">
        <i class="el-icon-location"></i>
        <span style="margin-left: 0.5em">{{ weatherArea }}</span>
        <img class="header_weather" :src="weatherImg" alt="" />
        <span style="margin-left: 0.5em">{{ weatherText }}</span>
      </div>
      <span style="flex: 2"></span>
      <span class="time-pane">
        {{ dateText }}
        <span style="margin: 0 0.5em">{{ dayNum }}</span>
        {{ timeStr }}
      </span>
      <div class="path-left">
        <img
          src="../../assets/images/bigScreen/equipment/enlarge.png"
          class="go-home"
          style="margin-left: 0.3rem; margin-right: 0"
          @click="handleClick"
          alt=""
          srcset=""
        />
      </div>
    </div>

    <!-- 布局中间部分 -->
    <div class="MainOuter">
      <div class="flex_1 flex-direction">
        <div class="flex_1 digging-box background-box" :style="{ backgroundImage: showProjects[0] ? `url(${showProjects[0].backgroundImg})` : '' }">
          <div style="height: 10.8%" class="hold-box"></div>
          <div class="hold-box-center">
            <el-select
              v-model="diggingYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <Digging style="height: 89.2%" ref="digging" :year="diggingYear" :projectTypeId="showProjects[0] ? showProjects[0].projectTypeId : ''" />
        </div>
        <div class="flex_1 lifting-box background-box" :style="{ backgroundImage: showProjects[1] ? `url(${showProjects[1].backgroundImg})` : '' }">
          <div style="height: 10.8%" class="hold-box"></div>
          <div class="hold-box-center">
            <el-select
              v-model="liftingYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <Lifting ref="lifting" style="height: 89.2%" :year="liftingYear" :projectTypeId="showProjects[1] ? showProjects[1].projectTypeId : ''" />
        </div>
        <div class="flex_1 agricultural-box background-box" :style="{ backgroundImage: showProjects[2] ? `url(${showProjects[2].backgroundImg})` : '' }">
          <div style="height: 10.8%" class="hold-box"></div>
          <div class="hold-box-center">
            <el-select
              v-model="agriculturalYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <Agricultural
            ref="agricultural"
            style="height: 89.2%"
            :year="agriculturalYear" :projectTypeId="showProjects[2] ? showProjects[2].projectTypeId : ''"
          />
        </div>
      </div>
      <div class="flex_2 flex-direction">
        <div class="flex_2 heartland-box background-box">
          <ModalMap
            ref="ModalMap"
            :options="mapOption"
            :productTypes="productTypes"
          />
        </div>
        <div class="flex_1 flow-box background-box">
          <div style="height: 11%"></div>
          <div class="hold-box-center">
            <div class="statistics">
              <span class="statistics-text">理赔平均时长</span>
              <span class="statistics-text"><span class="statistics-num">{{ caseNum || 0 }}</span>小时</span>
            </div>
          </div>
          <Flow ref="flow" style="height: 89%" @changeNum="(val)=> caseNum=val" />
        </div>
      </div>
      <div class="flex_1 flex-direction">
        <div class="flex_1 risk-box background-box">
          <div style="height: 10.8%" class="hold-box"></div>

          <div class="hold-box-center">
            <el-select
              v-model="riskYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <Risk ref="risk" style="height: 89.2%" :year="riskYear" />
        </div>
        <div class="flex_2 case-box background-box">
          <div style="height: 5.4%" class="hold-box"></div>
          <div class="hold-box-center">
            <el-select
              v-model="caseYear"
              class="popper-style"
              placeholder="请选择"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <Case ref="case" style="height: 94.6%" :year="caseYear" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { weatherInfo, amapGetIp } from "@/api/weather";
import { getWeek, getTimes } from "@/utils/getDate";
import {
  workStatistics,
  oilStatistics,
  oilTotal,
  workTotal,
  largeScreenSecurityAlarm,
  getLatLon,
  largeScreenInterfice,
} from "@/api/getData";
import dayjs from "dayjs";
import {
  filterAliMapData,
  filterMapData,
} from "@/components/CenterMap/mapUtils/index";
import chinaLi from "@/components/CenterMap/mapJson/chinaMap.json";
// --------------------------------------------新的模块组件--------------------------------------------
import Agricultural from "@/views/BigScreenDisplay/components/service/Agricultural";
import Digging from "@/views/BigScreenDisplay/components/service/Digging";
import Case from "@/views/BigScreenDisplay/components/service/case";
import Lifting from "@/views/BigScreenDisplay/components/service/Lifting";
import ModalMap from "@/views/BigScreenDisplay/components/service/ModalMap";
import Flow from "@/views/BigScreenDisplay/components/service/flow";
import Risk from "@/views/BigScreenDisplay/components/service/risk";
import { getProject } from "@/api/BigScreenDisplay";
import { isElement } from "lodash";
import { mapMutations } from "vuex";

import { getMapCarNumberInfo } from "@/api/Map/mapRequst.js";

import moment from "dayjs";

import {
  getAreaAlarmTop,
  getManHourStatistics,
  vehicleInfoCount,
  getProductClassList,
  getRealizeAlarm,
  getProductByProvince,
  getProductTypes,
} from "@/api/getHome";

export default {
  // `name`与路由保持一致
  name: "BigScrennDisplayService",
  components: {
    Agricultural,
    Digging,
    Case,
    Lifting,
    ModalMap,
    Flow,
    Risk,
  },
  data() {
    // this.intervalId = null;
    return {
      header1: true,
      header2: false,
      header3: false,
      header4: false,
      timeStr: "", // 时间
      timer: null, // 时间定时器
      pieOption: {}, //饼图
      mapOption: [], // 地图数据
      productTypes: [], // 种类总数
      roseOption: [], // 车型分类

      weatherArea: "",
      weatherImg: "",
      weatherText: "",
      dateText: "",
      timeOptions: [
        {
          value: "2024",
          label: "2024年",
        },
        {
          value: "2023",
          label: "2023年",
        },
        {
          value: "2022",
          label: "2022年",
        },
        {
          value: "2021",
          label: "2021年",
        },
      ],
      StatisticalCenterVlue: "2024",
      dayNum: "",
      dateText: "",
      allData: {},
      caseYear: "2024",
      riskYear: "2024",
      agriculturalYear: "2024",
      diggingYear: "2024",
      liftingYear: "2024",
      caseNum: 0,

      fullscreenchange: null,
      intervalId: null,
      showNum: 3,
      showIndex: 0,
      showProjects: [],
      bdProjects: [],
      intervalModel: null
    };
  },
  created() {
    const { name } = this.$store.state.userInfo;
    this.mapListData = chinaLi;
    if (name == "华北利星行") {
      (this.header1 = false), (this.header2 = true);
    } else if (name == "广东伟信市政") {
      this.header3 = true;
    }
    //  else if (name == "中国石油" || name == "YILI_TECH") {
    //   this.header4 = true;
    // }
  },
  // mounted
  activated() {
    let that = this;
    this.init();
    this.handleGetWeather();
    var datas = dayjs().day();
    var week = ["日", "一", "二", "三", "四", "五", "六"];
    this.dayNum = "周" + week[datas];
    this.dateText = moment().format("YYYY-MM-DD");
    this.timer = setInterval(() => {
      const today = moment(new Date()).format("HH:mm:ss");
      this.timeStr = `${today}`;
    }, 1000);

    // this.intervalId = setInterval(async () => {
    //   await this.init();
    // }, 1000 * 30);
    this.handlePolling();
    this.fullscreenchange = true;
    // 监听放大页面
    addEventListener("fullscreenchange", this.handleFullscreenchange);
    addEventListener("visibilitychange", this.handleVisibilitychange);
  },
  // destroyed
  deactivated() {
    clearInterval(this.timer); //销毁定时器
    // clearInterval(this.intervalId);
    clearTimeout(this.intervalId);
    clearTimeout(this.intervalModel);
    this.intervalModel = null;
    this.intervalId = null;
    this.fullscreenchange = null;
    removeEventListener("fullscreenchange", this.handleFullscreenchange);
    removeEventListener("visibilitychange", this.handleVisibilitychange);
  },

  methods: {
    async init() {
      this.$nextTick(() => {
        let list = [
          "ModalMap",
          "flow",
          "risk",
          "case",
        ];
        // let list = [
        //   "ModalMap",
        //   "flow",
        //   "risk",
        //   "case",
        //   "agricultural",
        //   "digging",
        //   "lifting"
        // ];
        list.map((val) => {
          this.$refs[val].init();
        });
      });

      
      getProject({
        accountName: this.$store.state.userInfo.name
      }).then(res=>{
        this.bdProjects = res.data.lpProjects;
        this.changeProjects();
        this.intervalModel = setInterval(() => {
          this.changeProjects();
        },2000)
      })
    },
    changeProjects() {
      if(this.showIndex >= this.bdProjects.length) {
        this.showIndex = 0;
      }else if ((this.showIndex + this.showNum) > this.bdProjects.length) {
        this.showProjects = [...JSON.parse(JSON.stringify(this.bdProjects)).splice(this.showIndex,this.bdProjects.length - this.showIndex), ...JSON.parse(JSON.stringify(this.bdProjects)).splice(0,(this.showIndex + this.showNum) + 1 - this.bdProjects.length)]
        this.showIndex = (this.showIndex + this.showNum) - this.bdProjects.length;
        return false;
      }
      this.showProjects = JSON.parse(JSON.stringify(this.bdProjects)).splice(this.showIndex,this.showNum);
      this.showIndex += this.showNum;
    },
    handleFullscreenchange() {
      this.init();
    },
    handleVisibilitychange() {
      if (document.visibilityState == "hidden") {
        clearTimeout(this.intervalId);
        this.intervalId = null;
        clearTimeout(this.intervalModel);
        this.intervalModel = null;
      } else {
        this.init();
        if (!this.intervalId) {
          this.handlePolling();
        }
      }
    },
    handlePolling() {
      this.intervalId = setTimeout(async () => {
        await this.init();
        // this.$refs.ModalMap.mapChange()
        clearTimeout(this.intervalId);
        clearTimeout(this.intervalModel);
        this.intervalModel = null;
        if (this.fullscreenchange) {
          this.handlePolling();
        }
      }, 1000 * 60);
    },
    // 获取区域报警排行数据
    async getAreaAlarmList() {
      const result = await getAreaAlarmTop();

      if (!(result.flag && result.obj.length)) return;
      let total = null;
      if (result.extend) {
        total = result.extend.number;
      }
      let numTotal = 0;
      result.obj.map((item) => (numTotal += item.areaAlarmTotal));
      // this.rankOption = result.obj.map(item => ({
      //   value: total ? Math.round(item.areaAlarmTotal / (total / 100)) : Math.round(item.areaAlarmTotal / (numTotal / 100)),
      //   label: item.areaName
      // }));

      // this.rankOption.splice(this.rankOption.length, 0, ...new Array(5 - this.rankOption.length).fill({ value: 0, label: '--' })); // 数组补漏
    },

    //工时统计时间类型change
    modalChange(val) {
      this.timeType = val;
      this.getData.intervalMark = val;
      this.getData.startTime = getTimes(val)[0];
      this.getData.endTime = getTimes(val)[1];
      this.getManHourList();
    },

    // 获取工时统计数据
    async getManHourList(data) {
      if (data) {
        const { vehicleStatisticalList } = data;
        this.lineOption = vehicleStatisticalList;
        return false;
      }
      await workStatistics(this.getData).then((res) => {
        if (res && res.obj) {
          const { vehicleStatisticalList } = res.obj;
          this.lineOption = vehicleStatisticalList;
        }
      });
      // await getManHourStatistics({ mode: this.timeType }).then(res => {
      //   if (!(res.flag && res.obj.length)) return;
      //   this.lineOption = res.obj;
      // });
    },

    //实时统计数据
    async getInfoCount(data = {}) {
      // await vehicleInfoCount().then(res => {
      //   if (!(res.flag && res.obj)) return;

      const { offline, vehicleTotal } = data;
      const rate =
        offline && vehicleTotal
          ? ((Number(offline) || 0) / (Number(vehicleTotal) || 0)) * 100
          : 0;
      this.vehicleTotal = vehicleTotal;
      this.infoCountOption = data;
      this.infoCountOption.acticeRate = rate.toFixed(1);
      // });
    },

    //油耗统计数据
    async getOilTotal(result = {}) {
      // const result = await oilTotal({ flag: 1 });

      // if (!(result && result.flag === 1)) return;

      const { todayOilTop5 } = result;
      this.oilOptions = result;
      this.roseOption = todayOilTop5;
      if (todayOilTop5.length < 5) {
        const pushNum = 5 - todayOilTop5.length;
        for (let index = 0; index < pushNum; index++) {
          this.roseOption.push({
            groupId: "",
            oil: "",
            plate: "",
            vehicleId: "",
          });
        }
        // (5 - todayOilTop5.length).map(()=> {
        //   todayOilTop5.push({
        //   groupId: "",
        //   oil: '',
        //   plate: "",
        //   vehicleId: ''
        //   })
        // })
      }
    },

    //工时统计数据
    async getWorkTotal(data = {}) {
      // const result = await workTotal();
      // if (!(result && result.flag === 1)) return;
      this.workOptions = data;
    },
    //获取油耗占比数据
    async getOilFront(data = {}) {
      // const result = await oilStatistics(this.oilData);
      // if (!(result && result.flag === 1)) return;
      const { sumData } = data;
      this.pieOption = sumData;
    },
    async largeScreenSecurityAlarm(data = {}) {
      this.visualDataInfo = {};
      // const { userId } = this.$store.state.userInfo;
      try {
        //   const result = await largeScreenSecurityAlarm({ userId });
        //   if (!(result && result.flag === 1)) return;
        data ? (this.visualDataInfo = data) : "";
        data && data.alarmCountGroupByAlarmType
          ? (this.rankOption = data.alarmCountGroupByAlarmType.map((item) => {
              return {
                value: item.alarmCount,
                progressValue:
                  data.alarmCount &&
                  data.alarmCount.todayAlarmCount &&
                  item.alarmCount
                    ? Math.round(
                        item.alarmCount /
                          (data.alarmCount.todayAlarmCount / 100)
                      )
                    : 0,
                label: item.alarmTypeName,
              };
            }))
          : "";
      } catch (err) {
        console.error(err);
      }
    },
    //获取实时报警数据
    async getRealizeAlarmList(data = []) {
      this.visualMediaDataInfo = [];
      try {
        //   const result = await getRealizeAlarm();

        // console.log(result);
        //   if (!(result && result.flag === 1)) return;

        data
          ? (this.visualMediaDataInfo = data.map((val) => {
              let isImageUrl = /\.(png|jpe?g|gif|svg)(\?.*)?$/;
              isImageUrl.test(val.imagePath)
                ? ""
                : (val.imagePath = require("../../assets/images/bigScreen/default.jpg"));
              val.alarmTime = val.alarmTime ? val.alarmTime.split(" ")[1] : "";
              return val;
            }))
          : "";
        // console.log(this.rankOption);
        // result.extend && result.extend.listRet ? this.rankOption = result.extend.listRet.map(item => ({
        //   value: Math.round(item.sumResult / (1 / 100)),
        //   label: item.labelName
        // })) : '';
      } catch (err) {
        console.error(err);
      }
    },

    // 获取产品种类分布情况
    async getProductTyps(data = []) {
      // const result = await getProductByProvince({ provinceName: this.provinceName });
      //抽取数据
      if (this.mapName === "china") {
        let dataList = await filterAliMapData(chinaLi, "", data);
        this.mapOption = dataList.filter((val) => val.value && val.value > 0);
      } else {
        const specialList = [
          "北京",
          "上海",
          "重庆",
          "天津",
          "北京市",
          "上海市",
          "重庆市",
          "天津市",
        ];
        let ddd = await filterAliMapData(
          this.mapListData,
          this.provinceName &&
            !(
              this.mapName.indexOf("省") > -1 ||
              this.mapName.indexOf("自治区") > -1 ||
              this.mapName.indexOf("特别") > -1
            )
            ? this.provinceName + "," + this.mapName
            : specialList.includes(this.mapName)
            ? this.mapName + ",市辖区"
            : this.mapName
        );
        this.mapOption = ddd.filter((val) => val.value && val.value > 0);
      }
      // let dataList = await filterAliMapData(chinaLi, '');
      // this.mapOption = dataList.filter(val=> val.value && val.value > 0);
      // console.log('------------------',dataList.filter(val=> val.name && val.name > 0));
      // if (result.flag !== 1) return;
      // const { mapResult, typeResult } = result.obj;
      // this.mapOption = mapResult;
      // this.productTypes = typeResult;
    },

    async handleGetWeather() {
      let cityData = await amapGetIp({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
      });
      let imgList = [
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain1.png", //小雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain2.png", //大雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain3.png", // 雷雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/cloud.png", //多云
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/snow.png", //雪
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/clear.png", // 晴
      ];
      //  {city: cityData.city}
      weatherInfo({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
        city: cityData.adcode,
      }).then((res) => {
        let weatherData = res.lives[0];
        // let high = weatherData.high.split(' ')[1];
        // let low = weatherData.low.split(' ')[1];
        this.weatherArea = cityData.city;
        this.weatherText =
          weatherData.temperature + "°" + " " + weatherData.weather;
        if (weatherData.weather.indexOf("雷") > -1) {
          this.weatherImg = imgList[2];
        } else if (weatherData.weather.indexOf("大雨") > -1) {
          this.weatherImg = imgList[1];
        } else if (weatherData.weather.indexOf("雨") > -1) {
          this.weatherImg = imgList[0];
        } else if (weatherData.weather.indexOf("雪") > -1) {
          this.weatherImg = imgList[4];
        } else if (weatherData.weather.indexOf("晴") > -1) {
          this.weatherImg = imgList[5];
        } else if (weatherData.weather.indexOf("云") > -1) {
          this.weatherImg = imgList[3];
        } else {
          this.weatherImg = imgList[3];
        }
      });
    },
    async handleMapChang(data) {
      if (this.backLoading) {
        return false;
      }
      this.backLoading = true;
      if (this.mapName === "china") {
        let initData = await filterMapData(this.mapListData, "", true);
        const pushData = {
          mapListData: JSON.parse(JSON.stringify(this.mapListData)),
          adcode: this.mapAdcode,
          mapName: this.mapName,
        };
        // this.mapHierarchyList.push({
        //   mapListData: JSON.parse(JSON.stringify(this.mapListData)),
        //   adcode: this.mapAdcode,
        //   mapName: this.mapName
        // })
        const filData = initData.filter(
          (val) =>
            val.name.indexOf(data.name) > -1 || data.name.indexOf(val.name) > -1
        )[0];
        let ddd = await this.downloadedMaps(filData.adcode, filData.name);
        if (ddd) {
          this.mapName = filData.name;
          this.mapAdcode = filData.adcode;
          this.mapHierarchyList.push(pushData);
          this.mapOption = ddd.filter((val) => val.value && val.value > 0);
        } else {
          this.$refs.ModalMap.isMapLoading = false;
        }
        // this.mapHierarchyList.push(pushData)
        // this.mapOption = ddd.filter(val=> val.value && val.value > 0)
      } else {
        const pushData = {
          mapListData: JSON.parse(JSON.stringify(this.mapListData)),
          adcode: this.mapAdcode,
          mapName: this.mapName,
        };
        const filData = this.mapListData.filter(
          (val) =>
            val.name.indexOf(data.name) > -1 || data.name.indexOf(val.name) > -1
        )[0];
        let ddd = await this.downloadedMaps(filData.adcode, filData.name);
        if (ddd) {
          this.mapName = filData.name;
          this.mapAdcode = filData.adcode;
          this.mapHierarchyList.push(pushData);
          this.mapOption = ddd.filter((val) => val.value && val.value > 0);
        } else {
          this.$refs.ModalMap.isMapLoading = false;
        }
      }
      this.backLoading = false;
    },

    async downloadedMaps(adcode = "100000", name) {
      let mapUrl = `https://geo.datav.aliyun.com/areas_v3/bound/${adcode}_full.json`;
      let resultData = await getLatLon({ url: mapUrl });
      if (!resultData.obj) {
        this.backLoading = false;
        this.$refs.ModalMap.isMapLoading = false;
        this.$message.error("暂不支持该区域缩放");
        return false;
      }
      const specialList = [
        "北京",
        "上海",
        "重庆",
        "天津",
        "北京市",
        "上海市",
        "重庆市",
        "天津市",
      ];
      //抽取数据
      let dataList = (this.data = await filterAliMapData(
        resultData,
        this.provinceName
          ? this.provinceName + "," + name
          : specialList.includes(name)
          ? name + ",市辖区"
          : name
      ));
      if (
        name.indexOf("省") > -1 ||
        name.indexOf("自治区") > -1 ||
        name.indexOf("特别") > -1
      ) {
        this.provinceName = name;
      }
      this.mapListData = JSON.parse(JSON.stringify(dataList));
      echarts.registerMap(name, resultData.obj);
      // this.mapObject = resultData.obj;
      return dataList;
    },
    async mapBackToUp() {
      const data = this.mapHierarchyList.pop();
      this.mapName = data.mapName;
      this.mapListData = data.mapListData;
      if (data.mapName === "china") {
        this.provinceName = "";
        let dataList = await filterAliMapData(data.mapListData, "");
        this.mapOption = dataList.filter((val) => val.value && val.value > 0);
      } else {
        let ddd = await filterAliMapData(data.mapListData, data.mapName);
        this.mapOption = ddd.filter((val) => val.value && val.value > 0);
      }
    },
    getFullScreenEl() {
      const { el } = this;
      // return (isElement('el')
      //   ? el
      //   : document.querySelector(el)) || document.documentElement;
      return this.$refs["BigScreenDisplay"];
    },
    /**
     * 进入全屏
     */
    requestFullscreen() {
      const fullscreenEl = this.getFullScreenEl();

      fullscreenEl.requestFullscreen();
      this.isFullscreen = true;
    },
    /**
     * 退出全屏
     */
    exitFullscreen() {
      document.exitFullscreen();
      this.isFullscreen = false;
    },
    handleClick() {
      this.SetFullscreen();
      // const isFullscreen = !this.isFullscreen;

      // if (isFullscreen) { // 进入全屏
      //   this.requestFullscreen();
      // } else {
      //   // 退出全屏
      //   this.exitFullscreen();
      // }
    },
    handleGoPath(name) {
      this.$router.push({
        path: "/" + name,
      });
    },
    ...mapMutations(["SetFullscreen"]),
  },
};
</script>


<style lang="scss" scoped>
@import "./ServiceStyle.scss";
@import "./BigScreenPublic.scss";
.bg1 {
  background-image: url('../../assets/images/bigScreen/home/titleBg.png');
}
.bg2 {
  background-image: url('../../assets/images/bigScreen/top3.png');
}
.bg3 {
  background-image: url('../../assets/images/bigScreen/top2.png');
}
.bg4 {
  background-image: url('../../assets/images/bigScreen/zgsyBg.png');
}
</style>

<style lang="scss">
.popper-style {
  width: 68px;
  input {
    color: #ffffff;
    border: 1px solid #0267cb;
    background: rgba(157, 254, 255, 0.2);
    padding-left: 0.04rem;
    padding-right: 0.04rem;
  }
  .el-input__inner {
    height: auto;
    line-height: initial;
    padding: 2px;
    padding-right: 22px;
  }
  .el-input__icon {
    height: 0.38rem;
    line-height: 0.38rem;
  }
  .el-input__suffix {
    right: 0;
  }
}
.data-center {
  .el-loading-mask {
    background-color: rgba(24, 34, 55, 0.8);
  }
}
</style>